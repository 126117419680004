import React, { useEffect } from "react";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

interface FacebookLoginButtonProps {
  onLoginSuccess: (data: any) => void;
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = ({ onLoginSuccess }) => {
  useEffect(() => {
    // Load Facebook SDK
    if (!window.FB) {
      ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = () => {
        window.FB.init({
          appId: "432736705920119", // Your Facebook App ID
          cookie: true,
          xfbml: false,
          version: "v19.0",
        });
      };
    }
  }, []);

  const handleLogin = () => {
    window.FB.login((response: any) => {
      if (response.authResponse) {
        window.FB.api("/me", { fields: "name,email" }, (userInfo: any) => {
          onLoginSuccess(userInfo);
        });
      } else {
        console.error("Facebook login failed");
      }
    }, { scope: "email,public_profile" });
  };

  return (
    <button
      onClick={handleLogin}
      style={{
        padding: "10px",
        width: "100%",
        backgroundColor: "#1877F2",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        fontWeight: "bold",
        cursor: "pointer"
      }}
    >
      Continue with Facebook
    </button>
  );
};

export default FacebookLoginButton;
