import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import { Helmet, HelmetProvider } from "react-helmet-async";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";


const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        {/* <ThemeProvider> */}
        {/* <BrowserRouter> */}
        {/* <HelmetProvider> */}
            <App />
        {/* </HelmetProvider> */}
        {/* </BrowserRouter> */}
        {/* </ThemeProvider> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
