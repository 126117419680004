import React from "react";
import "./vertexresult.css";

interface VertexAIDisplaySectionProps {
    data: any;
}

const VertexAIDisplaySection: React.FC<VertexAIDisplaySectionProps> = ({ data }) => {
    const results = Array.isArray(data) ? data : [data];

    if (!results || results.length === 0) return <p>No results found.</p>;

    return (
        <div className="space-y-6">
            {results.map((item: any, index: number) => {
                // Get Top Web Entity
                let topWebEntity = null;
                if (item?.web_detection?.webEntities?.length > 0) {
                    topWebEntity = item.web_detection.webEntities.reduce((prev: any, curr: any) =>
                        curr.score > prev.score ? curr : prev
                    );
                }

                return (
                    <div key={index} className="vertex-result border border-gray-300 rounded-md p-4 mb-4 bg-gray-50">
                        <div className="object-detect mr-1">
                            <div className="web-description">
                                {/* Top Web Entity */}
                                {topWebEntity && (
                                    <div className="mb-4">
                                        <p>{topWebEntity.description} ({(topWebEntity.score * 100).toFixed(2)}%)</p>
                                    </div>
                                )}
                            </div>
                            <div className="localized-object">
                                {/* Localized Objects Table */}
                                {item?.localized_objects?.length > 0 && (
                                    <div className="mb-4">
                                        <h4 className="text-md font-semibold mb-1">Localized Objects</h4>
                                        <table className="w-full table-auto border border-gray-200">
                                            <thead>
                                                <tr className="bg-gray-100">
                                                    <th className="border px-4 py-2 text-left">Object Name</th>
                                                    <th className="border px-4 py-2 text-left">Confidence</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.localized_objects.map((obj: any, i: number) => (
                                                    <tr key={i}>
                                                        <td className="border px-4 py-2">{obj.name}</td>
                                                        <td className="border px-4 py-2">{(obj.score * 100).toFixed(2)}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="label-descriptive ml-5">
                            {/* Labels in descriptive format */}
                            {item?.labels?.length > 0 && (
                                <div className="mb-4">
                                    <h4 className="text-md font-semibold mb-1">Detected Labels</h4>
                                    <ul className="list-disc pl-5">
                                        {item.labels.map((label: any, i: number) => (
                                            <li key={i}>
                                                {label.description} ({(label.score * 100).toFixed(2)}%)
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default VertexAIDisplaySection;
