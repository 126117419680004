// MyRoutes.tsx
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { pages } from "./routes-pages"; // your new centralized config
import ScrollToTop from "./ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import Footer from "shared/Footer/Footer";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import Page404 from "containers/Page404/Page404";
import { ThemeProvider } from "@material-tailwind/react";

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || (typeof window !== "undefined" ? window.innerWidth : 1024);

  return (
    <ThemeProvider>
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
    </ThemeProvider>
  );
};

export default MyRoutes;
