import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import FacebookLoginButton from "../../components/FacebookLoginButton"; // ✅ NEW IMPORT

export interface BusinessSignupProps {
  className?: string;
}
const loginSocials = [
    {
        name: "Continue with Facebook",
        href: "#",
        icon: facebookSvg,
    },
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

const BusinessSignup: FC<BusinessSignupProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [contact_name, setContactName] = useState("");
  const [password2, setPassword2] = useState("");

  const handleFacebookCallback = (response: any) => {
    if (!response?.email) {
      toast("Facebook login failed.");
      return;
    }

    let data = new FormData();
    data.append("email", response.email);
    data.append("name", response.name);
    data.append("type", "2");

    fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      body: data,
    })
      .then((res) =>
        res.json().then((res) => {
          if (res.status === "wrong") {
            toast("Email already exists. Please try another one.");
          } else {
            localStorage.setItem("email", response.email);
            localStorage.setItem("name", response.name);
            localStorage.setItem("type", "2");
            window.location.href = "/";
          }
        })
      )
      .catch((err) => console.log(err));
  };

  const handleCredentialResponse = (response: any) => {
    const responsePayload = decodeJwtResponse(response.credential);
    let data = new FormData();
    data.append("email", responsePayload.email);
    data.append("name", responsePayload.name);
    data.append("type", "2");

    fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      body: data,
    })
      .then((res) =>
        res.json().then((res) => {
          localStorage.setItem("email", responsePayload.email);
          localStorage.setItem("name", responsePayload.name);
          localStorage.setItem("type", "2");
          window.location.href = "/";
        })
      )
      .catch((err) => console.log(err));
  };

  const decodeJwtResponse = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const initializeGSI = () => {
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id:
            "866529374797-npcd5h9o0ir5vjoiaj5utgcbh7bclqvj.apps.googleusercontent.com",
          callback: handleCredentialResponse,
        });

        const buttonDiv = document.getElementById("google-signin-button");
        if (buttonDiv) {
          window.google.accounts.id.renderButton(buttonDiv, {
            theme: "outline",
            size: "large",
          });
        }
      }
    };

    if (window.google && window.google.accounts) {
      initializeGSI();
    } else {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGSI;
      document.body.appendChild(script);
    }
  }, []);

  const onSubmit = () => {
    if (
      company_name === "" ||
      contact_name === "" ||
      role === "" ||
      userEmail === "" ||
      password === "" ||
      password2 === ""
    ) {
      toast("Please input all fields!");
      return;
    }

    if (password !== password2) {
      toast("Passwords do not match. Please confirm your password!");
      return;
    }

    if (password.length < 8) {
      toast("Password should be at least 8 characters!");
      return;
    }

    let data = new FormData();
    data.append("company_name", company_name);
    data.append("contact_name", contact_name);
    data.append("role", role);
    data.append("email", userEmail);
    data.append("password", password);
    data.append("password_confirmation", password2);
    data.append("type", "2");

    fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      body: data,
    })
      .then((res) =>
        res.json().then((res) => {
          if (res.status === "wrong") {
            toast("Email already exists. Please use another email.");
          } else {
            navigate("/business-login");
          }
        })
      )
      .catch((err) => console.log(err));
  };

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl md:text-5xl font-semibold text-neutral-900 justify-center">
          Business Signup
        </h2>
        <ToastContainer />
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            <div id="google-signin-button" className="m-auto w-full" />
            <div className="m-auto w-full">
              <FacebookLoginButton onLoginSuccess={handleFacebookCallback} />
            </div>
          </div>

          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-200 dark:border-neutral-800"></div>
          </div>

          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800">Company Name</span>
              <Input
                type="text"
                placeholder="Company Name"
                value={company_name}
                onChange={(e) => setCompanyName(e.target.value)}
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800">Company Role</span>
              <Input
                type="text"
                placeholder="Company Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800">Company Contact Name</span>
              <Input
                type="text"
                placeholder="Contact Name"
                value={contact_name}
                onChange={(e) => setContactName(e.target.value)}
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800">Email address</span>
              <Input
                type="email"
                placeholder="example@example.com"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800">Password</span>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1"
              />
            </label>

            <label className="block">
              <span className="text-neutral-800">Confirm Password</span>
              <Input
                type="password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                className="mt-1"
              />
            </label>

            <ButtonPrimary type="button" onClick={onSubmit}>
              Continue
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700">
            Already have an account? <Link to="/business-login">Sign in</Link>
          </span>
          <span className="block text-center text-neutral-700">
            <Link to="/login">Personal Sign In</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BusinessSignup;




