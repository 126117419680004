import React, { FC } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import { NavLink, useNavigate } from "react-router-dom";
import "./labzippervideos.css";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import SectionSliderNewCategories from "./SectionSliderNewCategories";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LoadingSpinner from "./LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";

export interface LabZipperVideosPageProps {
    className?: string;
}
interface VideoItem {
    id: number;
    title: string;
    category: string;
    path: string;
    user_id: string;
    created_at: string;
    updated_at: string;
}
const LabZipperVideosPage: FC<LabZipperVideosPageProps> = ({
    className = "",
}) => {
    const user_email = localStorage.getItem("email") || "";
    const navigate = useNavigate();
    const stepTwo = () => {
        if (!user_email) navigate("/login");
        else navigate("/lap-zipper/dashboard");
    };
    const [selectedItem, setSelectedItem] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [earnings, setEarnings] = useState("0.00");
    const [Prompt, setPrompt] = useState("");
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [videos, setVideos] = useState<VideoItem[]>([]);
    const [link, setLink] = useState<string>("");
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const [videoUrl, setVideoUrl] = useState(""); // Store JSON response data
    const [isLoading, setIsLoading] = useState(false);  // Add loading state


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => { setIsModalOpen(false); setPrompt(""); };

    const sendVertex = () => {
        let data = new FormData();
        data.append("path", selectedItem);
        data.append("type", "video");
        data.append('prompt', Prompt);
        data.append('email', user_email)
        if (Prompt == "") {
            setIsLoading(false);
            toast("Please input prompt.");
        } else {
            setIsLoading(true);
            setIsModalOpen(false);
            fetch(process.env.REACT_APP_API_URL + "/video/analyze", {
                method: "POST",
                body: data,
            })
                .then((res) => res.json().then((res) => {
                    setIsLoading(false);
                    console.log(res);

                    // Creating new object of the file
                    const fileURL = res.video_url

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = "download"; // Change the filename as needed
                    alink.click();
                    setPrompt("");
                    setLink(fileURL);
                }))
                .catch((err) => {
                    setPrompt("");
                    toast("File does not exist.");
                    setIsLoading(false);
                    console.log(err);
                });
        }

    };
    const fetchBestBuy = () => { };
    useEffect(() => {
        if (link != "" && linkRef.current) {
            linkRef.current.click();
        }
    }, [link]);
    useEffect(() => {
        const user_email = localStorage.getItem("email") || "";
        let data = new FormData();
        data.append("email", user_email);
        fetch(process.env.REACT_APP_API_URL + "/get_earnings", {
            method: "POST",
            body: data,
        })
            .then((res) =>
                res.json().then((res) => {
                    setEarnings(res);
                })
            )
            .catch((err) => console.log(err));

        fetch(process.env.REACT_APP_API_URL + "/get_video_categories", {
            method: "POST",
            body: data,
        })
            .then((res) =>
                res.json().then((res) => {
                    setCategories(res);
                })
            )
            .catch((err) => console.log(err));
        onSearch();
    }, []);
    const onSearch = () => {
        let data = new FormData();
        data.append("email", localStorage.getItem("email") || "");
        data.append("term", searchTerm);
        data.append("selected_category", selectedCategory);
        data.append("gallery_type", "video");
        fetch(process.env.REACT_APP_API_URL + "/get_gallery", {
            method: "POST",
            body: data,
        })
            .then((res) =>
                res.json().then((res) => {
                    setVideos(res);
                })
            )
            .catch((err) => console.log(err));
    };
    const onCategoryChanged = (category: any) => {
        setSelectedCategory(category);
        onSearch();
    };
    const resetSearch = () => {
        setSearchTerm("");
        setSelectedCategory("");
        onSearch();
    };
    return (
        <>
            <ToastContainer />
            <nav className="flex mt-10 ml-20" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <NavLink
                            end
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                            to={"/"}
                        >
                            <svg
                                className="w-3 h-3 me-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                            </svg>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg
                                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 9 4-4-4-4"
                                />
                            </svg>
                            <NavLink
                                end
                                rel="noopener noreferrer"
                                className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                                to="/lab-zipper/dashboard"
                            >
                                Telcron Rewards
                            </NavLink>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg
                                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 6 10"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 9 4-4-4-4"
                                />
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                                Videos Gallery
                            </span>
                        </div>
                    </li>
                </ol>
            </nav>
            {/* {videoUrl && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Processed Video</h2>
                        <video controls autoPlay width="100%">
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <button onClick={() => setVideoUrl("")}>Close</button>
                    </div>
                </div>
            )} */}

            <div
                className={`nc-PageAbout overflow-hidden relative ${className}`}
                data-nc-id="PageAbout"
            >
                <Helmet>
                    <title>LabZipper</title>
                </Helmet>
                {isModalOpen && (
                    <div
                        className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-10"
                        onClick={closeModal}
                    >
                        <div
                            className="send-video-top bg-white p-6 rounded-lg shadow-lg max-w-lg w-full items-center"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="">
                                <h2 className="text-xl font-semibold mb-4">
                                    Do you want to send this item to Vertex AI?
                                </h2>
                            </div>
                            <div className="video-prompt">
                                <input
                                    type="text"
                                    placeholder="Please input Prompts"
                                    value={Prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    className="mt-1"
                                />
                            </div>
                            <div className="send-video">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 mt-4 rounded"
                                    onClick={sendVertex}
                                >
                                    Send to Vertex AI
                                </button>
                            </div>

                        </div>
                    </div>
                )}
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-10 mt-20">
                    <div className="top-container !mt-4">
                        <div className="earnings-container">
                            <NavLink
                                end
                                rel="noopener noreferrer"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
                                to="/lab-zipper/dashboard"
                            >
                                Upload more videos
                            </NavLink>

                            <NavLink
                                end
                                rel="noopener noreferrer"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded"
                                to="/lab-zipper/images/gallery"
                            >
                                Images gallery
                            </NavLink>
                            {/* <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={fetchBestBuy}
                            >
                                Fetch from BestBuy
                            </button> */}
                        </div>
                        <div className="top-paragraph">
                            <p>
                                Earn Point 5 per video search. Start earning now
                                with Telcron Rewards.
                            </p>
                        </div>
                    </div>
                    <div className="search-container !mt-2">
                        <div className="input-group">
                            <input
                                type="search"
                                name="search"
                                className="form-control shadow-none searchInput"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <button
                                type="submit"
                                className="btn btn-primary videosearchBTn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                onClick={onSearch}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="top-container !mt-2">
                        <div className="earnings-container">
                            <ButtonPrimary
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => {
                                    resetSearch();
                                }}
                            >
                                Reset Search
                            </ButtonPrimary>
                        </div>
                        <div className="top-paragraph">
                            <p className="earnings">
                                <b>Total Earnings:</b> Point {earnings}
                            </p>
                        </div>
                    </div>
                    <div className="relative py-2 !mt-8">
                        <SectionSliderNewCategories
                            categories={categories}
                            categoryChanged={onCategoryChanged}
                            categoryCardType="card4"
                            itemPerRow={3}
                            sliderStyle="style2"
                            uniqueClassName="PageHome_s2"
                        />
                    </div>
                    {/* Display loading spinner when processing */}
                    {isLoading && <LoadingSpinner />}
                    <div className="image-grid" id="media-grid">
                        {videos.map((video) => {
                            const videourl = `${process.env.REACT_APP_BACKEND_URL}/${video.path}`;
                            return (
                                <div
                                    key={video.path} // ✅ Add a unique key to prevent React re-render issues
                                    className="grid-item"
                                    onClick={() => {
                                        console.log("Selected video:", video.path); // ✅ Debugging log
                                        setSelectedItem(video.path); // ✅ Ensure state updates correctly
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <video controls>
                                        <source src={videourl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LabZipperVideosPage;
