// src/routes-pages.ts

import HomePage from "containers/PageHome/HomePage";
import PageHome from "containers/PageHome/PageHome";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import CheckOutOrder from "containers/CheckOutPage/CheckOutOrder";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import OurTeam from "containers/PageAbout/OurTeam";
import OurPartners from "containers/PageAbout/OurPartners";
import OurCapabilities from "containers/PageAbout/OurCapabilities";
import ResourceProducts from "containers/PageServices/ResourceProducts";
import ResourceReferenceLinks from "containers/PageServices/ResourceReferenceLinks";
import RfExposure from "containers/PageServices/RfExposure";
import ProductSafety from "containers/PageServices/ProductSafety";
import Emc from "containers/PageServices/Emc";
import Esource from "containers/PageServices/Esource";
import ComplianceManagement from "containers/PageServices/ComplianceManagement";
import Consulting from "containers/PageServices/Consulting";
import ScheduleTesting from "containers/LabReservation/ScheduleTesting";
import Faqs from "containers/LabReservation/Faqs";
import SubmitLabReview from "containers/LabReservation/SubmitLabReview";
import Rewards from "containers/LabReservation/Rewards";
import SubmitRfq from "containers/SubmitRfq/SubmitRfq";
import SubmitRfqDevices from "containers/SubmitRfq/Devices";
import SubmitRfqModules from "containers/SubmitRfq/Modules";
import SubmitRfqCertified from "containers/SubmitRfq/Certified";
import ReserveLab from "containers/SubmitRfq/ReserveLab";
import LabZipperPage from "containers/LabZipper/LabZipperPage";
import LabZipperDashboardPage from "containers/LabZipper/LabzipperDashboardPage";
import LabZipperImagesPage from "containers/LabZipper/LabZipperImagesPage";
import LabZipperVideosPage from "containers/LabZipper/LabzipperVideosPage";
import LabRatingDirectory from "containers/LabListing/LabRatingDirectory";
import ViewListing from "containers/LabListing/ViewListing";
import SubmitListing from "containers/LabListing/SubmitListing";
import Contract from "containers/LabListing/Contract";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import BusinessSignUp from "containers/PageSignUp/BusinessSignUp";
import BusinessLogin from "containers/PageLogin/BusinessLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import Store from "containers/CheckOutPage/Store";

export const pages = [
  { path: "/", component: HomePage },
  { path: "/home", component: PageHome },
  { path: "/home-2", component: PageHome2 },
  { path: "/home-3", component: PageHome3 },
  { path: "/about", component: PageAbout },
  { path: "/contact-us", component: PageContact },
  { path: "/about/our-team", component: OurTeam },
  { path: "/about/our-partners", component: OurPartners },
  { path: "/about/our-capabilities", component: OurCapabilities },
  { path: "/services/resources/products", component: ResourceProducts },
  { path: "/services/resources/reference-links", component: ResourceReferenceLinks },
  { path: "/services/resources/rf-exposure", component: RfExposure },
  { path: "/services/resources/product-safety", component: ProductSafety },
  { path: "/services/resources/emc", component: Emc },
  { path: "/services/eSource", component: Esource },
  { path: "/services/compliance-management", component: ComplianceManagement },
  { path: "/services/consulting", component: Consulting },
  { path: "/lab-reservation/schedule-testing", component: ScheduleTesting },
  { path: "/lab-reservation/faqs", component: Faqs },
  { path: "/lab-reservation/submit-lab-review", component: SubmitLabReview },
  { path: "/lab-reservation/rewards", component: Rewards },
  { path: "/submit-rfq", component: SubmitRfq },
  { path: "/submit-rfq/devices", component: SubmitRfqDevices },
  { path: "/submit-rfq/modules", component: SubmitRfqModules },
  { path: "/submit-rfq/get-certified", component: SubmitRfqCertified },
  { path: "/reserve-lab", component: ReserveLab },
  { path: "/lab-zipper", component: LabZipperPage },
  { path: "/lab-zipper/dashboard", component: LabZipperDashboardPage },
  { path: "/lab-zipper/images/gallery", component: LabZipperImagesPage },
  { path: "/lab-zipper/videos/gallery", component: LabZipperVideosPage },
  { path: "/lab-rating-directory", component: LabRatingDirectory },
  { path: "/contract", component: Contract },
  { path: "/lab-rating-directory/view-listing", component: ViewListing },
  { path: "/lab-rating-directory/submit-listing", component: SubmitListing },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/business-signup", component: BusinessSignUp },
  { path: "/business-login", component: BusinessLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/about/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  { path: "/listing-experiences", component: ListingExperiencesPage },
  { path: "/listing-experiences-map", component: ListingExperiencesMapPage },
  { path: "/listing-experiences-detail", component: ListingExperiencesDetailPage },
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-flights", component: ListingFlightsPage },
  { path: "/checkout", component: CheckOutOrder },
  { path: "/pay-done", component: PayPage },
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/store", component: Store },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
];
